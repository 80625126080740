.sidePanel {
  border-right: 1px solid #edf0f5;
  background: #fff;
  margin-top: 30px;
}

.sidePanelContent {
  transition: all 0.3s ease-in-out;
  margin-top: 0;
}
